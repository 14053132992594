import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const PurchesCourseForm = () => {
  const [verified, setVerified] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
const [email, setEmail] = useState("");
const [message, setMessage] = useState("");
  function onChange(value: any) {
    setVerified(true);
  }
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Invalid email")
      .matches(emailRegex, "Invalid email"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "too short")
      .max(10, "too long"),
    message: Yup.string().required("Message is required"),
  });

  const handleChangeName = (e: any) => {
     setName(e.target.value);
  };
  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };
  const handleChangePhone = (e: any) => {
    setPhoneNumber(e.target.value);
  };
  const handleChangeMessage = (e: any) => {
    setMessage(e.target.value);
  };

  const handleSubmit =(e: any) => {
   // e.preventDefault();

    const _fullDetails: string = `Student Details:
                      Name: ${name},
                      Phone Number: ${phoneNumber},
                      Email: ${email},

                      Message: ${message}
                      `;
    const ServiceId = 'service_ul9cgmd';
    const templateId= 'template_i4p31tc';
    const publicKey = 'loRVbsarLgqgsPejP';
    
    const templateParams = {
        from_name: name,
        from_email: email,
        to_name: "Learnwith Prayag",
        message:_fullDetails
    };
    
    emailjs.send(ServiceId, templateId, templateParams, publicKey)
    .then((response) => {
      alert("Email send successfully..!!!!")
    console.log("Email send successfully", response);
    setName('');
    setEmail('');
    setMessage('');
    setPhoneNumber('');

    })
    .catch((error) => {
      alert("Error sending email!!!!")
        console.log("Error with send email", error);
    })
    
    }

  return (
    <section>
      <div className="td_height_120 td_height_lg_80"></div>

      <div className="container">
        <div className="row td_gap_y_40">
          <div className="col-lg-6">
            <div className="td_form_card td_style_1 td_radius_10 td_gray_bg_5">
              <div className="td_form_card_in">
                <h2 className="td_fs_36 td_mb_20">Get Access Online Course</h2>
                <hr />
                <div className="td_height_30 td_height_lg_30"></div>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    message: "",
                    phoneNumber: "",
                  }}
                  onSubmit={handleSubmit}
                  
                >
                  <Form>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="td_form_field td_mb_30 td_medium td_white_bg"
                      placeholder="Name *"
                      value={name}
                      onChange={handleChangeName}
                    />
                    <ErrorMessage name="name">
                      {(msg) => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      className="td_form_field td_mb_30 td_medium td_white_bg"
                      placeholder="Eame *"
                      value={email}
                      onChange={handleChangeEmail}
                    />
                    <ErrorMessage name="email">
                      {(msg) => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                    <Field
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="td_form_field td_mb_30 td_medium td_white_bg"
                      placeholder="Phone Number *"
                      value={phoneNumber}
                      onChange={handleChangePhone}
                    />
                    <ErrorMessage name="phoneNumber">
                      {(msg) => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                    <Field
                      type="textarea"
                      id="message"
                      name="message"
                      className="td_form_field td_mb_30 td_medium td_white_bg"
                      placeholder="Message *"
                      value={message}
                      onChange={handleChangeMessage}
                    />
                    <ErrorMessage name="message">
                      {(msg) => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                    <div className="td_form_card_text_2 td_mb_50">
                      <br />
                      <ReCAPTCHA
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        onChange={onChange}
                      />
                    </div>
                    <div className="td_form_card_bottom td_mb_25">
                      <button
                        type="submit"
                        disabled={!verified}
                        className="td_btn td_style_1 td_radius_10 td_medium"
                      >
                        <span className="td_btn_in td_white_color td_accent_bg">
                          <span>Sign In</span>
                        </span>
                      </button>

                      <div className="td_form_social td_fs_20">
                        Any query, Reach out to me via WhatsApp
                        <Link
                          to="https://shorturl.at/mKW25"
                          className="td_center"
                        >
                          <i className="fa-brands fa-whatsapp"></i>
                        </Link>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="td_sign_thumb">
              <img 
                src="assets/img/others/Online_course.jpg"
                alt=""
                className="w-100 td_radius_10 shadow"
              />
            </div>
          </div>
        </div>
        <div className="td_error text-left">
          <Link to="/courselist" className="td_btn td_style_1 td_radius_10 td_medium">
            <span className="td_btn_in td_white_color td_accent_bg">
              <span>Go Back To Courses</span>
              <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.1575 4.34302L3.84375 15.6567" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M15.157 11.4142C15.157 11.4142 16.0887 5.2748 15.157 4.34311C14.2253 3.41142 8.08594 4.34314 8.08594 4.34314" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg> 
            </span>             
          </Link>
        </div>
      </div>
      
      <div className="td_height_120 td_height_lg_80"></div>
    </section>
  );
};
