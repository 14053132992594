import React from "react";
import { Link } from "react-router-dom";

export const PrivacyAndPolicy = () => {
  return (
    <section className="td_gray_bg_5">
      <div className="td_height_120 td_height_lg_80"></div>
      <div className="container">
        <div className="td_page_heading_in">
          <br />
          <h1 className="td_white_color td_fs_48 td_mb_10">
            Privacyc & Policy
          </h1>
          <ol className="breadcrumb m-0 td_fs_20 td_opacity_8 td_semibold td_white_color">
            <li className="breadcrumb-item active">
              {" "}
              Effective Date: 20/11/2024
            </li>
          </ol>
        </div>
        <br />
      </div>
      <div className="container">
        <div
          className="td_section_heading td_style_1 text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        ></div>
        <div className="td_accordian_body td_fs_18">
          <p>
            At RootPromptNext (OPC) Pvt Ltd, which operates{" "}
            <Link to="www.learnwithprayag.com">
              <b>www.learnwithprayag.com</b>
            </Link>{" "}
            , a division of RootPromptNext(OPC) Pvt Ltd, we respect your privacy
            and are committed to protecting your personal information. This
            Privacy Policy explains the types of data we collect, how we process
            it, and the measures we take to ensure your data remains secure. By
            using our website and services, you agree to this policy.
          </p>
        </div>
        <div className="td_height_50 td_height_lg_50"></div>
        <div
          className="td_accordians td_style_1 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <div className="td_accordian active">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Scope of Policy
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                This Privacy Policy applies to all visitors, users, and
                customers of <Link to="www.learnwithprayag.com"><b>www.learnwithprayag.com</b></Link>, regardless of the country
                of access. The policy covers
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    Collection of personal and non-personal data.
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    Use, sharing, and retention of your data.
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    Security practices and your rights under applicable privacy
                    laws.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Information We Collect
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                We collect both personal and non-personal information through
                various methods:
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Personal Information
                    </p>
                    <br />
                    <p>
                      Personal data is collected directly from you when you:
                      <ul>
                        <br />
                        <li>
                          <p>
                            Register on our platform to access live or recorded
                            courses.
                          </p>
                        </li>
                        <li>
                          <p>
                            Fill out forms, such as when contacting us or
                            subscribing to our updates.
                          </p>
                        </li>
                        <li>
                          <p>
                            Make a purchase or process payment through the
                            platform.
                          </p>
                        </li>
                      </ul>
                    </p>
                    <br />
                    <p>
                      Examples of personal information include:
                      <ul>
                        <br />
                        <li>
                          <p>
                            <b>Identification Data:</b> Full name, date of
                            birth, and contact details (email address, phone
                            number).
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Financial Data:</b> Payment card details or
                            transaction information processed via secure
                            third-party payment gateways.
                          </p>
                        </li>
                      </ul>
                    </p>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Technical and Usage Data
                    </p>
                    <br />
                    <p>
                      Automatically collected information when you interact with
                      our website:
                      <ul>
                        <br />
                        <li>
                          <p>
                            <b>Device Information: </b> Type of device,
                            operating system, and browser version.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Usage Metrics:</b> Pages visited, time spent on
                            the website, navigation paths, and search queries.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>IP Address and Location: </b> Helps us monitor
                            suspicious activities and provide region-specific
                          </p>
                        </li>
                      </ul>
                    </p>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Cookies and Tracking Technologies
                    </p>
                    <br />
                    <p>
                      Our website uses cookies and similar tools to:
                      <ul>
                        <br />
                        <li>
                          <p>Maintain session continuity when you log in.</p>
                        </li>
                        <li>
                          <p>
                            Personalize your browsing experience and suggest
                            relevant courses.
                          </p>
                        </li>
                        <li>
                          <p>
                            Track aggregated statistics for analytics and
                            performance monitoring.
                          </p>
                        </li>
                      </ul>
                      <br />
                      <span>
                        For more details, please refer to our Cookie Policy
                        [link or section if applicable].
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                How We Use Your Data
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                We use the information collected for the following purposes:
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Service Delivery
                    </p>

                    <ul>
                      <br />
                      <li>
                        <p>
                          To process your registration and provide access to
                          purchased courses.
                        </p>
                      </li>
                      <li>
                        <p>
                          To enable live classes, recorded lessons, and
                          interactive tools.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Communication and Support
                    </p>
                    <ul>
                      <br />
                      <li>
                        <p>
                          Send email confirmations for registrations, payments,
                          or course-related updates.
                        </p>
                      </li>
                      <li>
                        <p>
                          Respond to queries submitted via email or the contact
                          form.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Personalization and Improvement
                    </p>

                    <ul>
                      <br />
                      <li>
                        <p>
                          Tailor course recommendations based on your
                          preferences and browsing history.
                        </p>
                      </li>
                      <li>
                        <p>
                          Monitor user behavior to improve the platform’s
                          performance and user experience.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Legal and Compliance Obligations
                    </p>

                    <ul>
                      <br />
                      <li>
                        <p>
                          Comply with legal requirements, including financial
                          reporting, tax filings, and responding to court orders
                          or subpoenas.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Sharing of Information
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                We may share your information with trusted entities in limited
                scenarios:
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Service Providers
                    </p>
                    <br />
                    <p>
                      Third-party providers who assist us in delivering our
                      services, including:
                      <ul>
                        <br />
                        <li>
                          <p>
                            <b>Payment Gateways:</b> For secure processing of
                            your transactions.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Email and Hosting Providers:</b> To facilitate
                            communication and manage server storage.
                          </p>
                        </li>
                      </ul>
                      <br />
                      These partners are bound by confidentiality agreements and
                      use your data solely to provide contracted services.
                    </p>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Legal Requirements
                    </p>
                    <br />

                    <ul>
                      <li>
                        <p>
                          Your data may be disclosed to law enforcement,
                          regulatory bodies, or other authorized parties when
                          required by law.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Business Transfers
                    </p>
                    <br />

                    <ul>
                      <li>
                        <p>
                          In the event of a merger, acquisition, or asset sale,
                          your information may be transferred to the new
                          ownership.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Data Retention
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                We retain personal data for as long as necessary to fulfill the
                purposes outlined in this Privacy Policy or as required by law.
                Specifically:
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <p>
                      <ul>
                        <li>
                          <p>
                            Account-related data is stored until you delete your
                            account or after a period of inactivity (e.g., 24
                            months).
                          </p>
                        </li>
                        <li>
                          <p>
                            Payment and financial records are kept as required
                            by tax laws and financial regulations.
                          </p>
                        </li>
                        <li>
                          <p>
                            Usage and analytics data are retained for improving
                            platform performance but anonymized where possible.
                          </p>
                        </li>
                      </ul>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Security Measures
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                We prioritize the security of your personal data and implement
                the following measures:
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <p>
                      <ul>
                        <li>
                          <p>
                            <b>Encryption: </b> Sensitive data is encrypted both
                            in transit and at rest.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Access Controls: </b> Only authorized personnel
                            with a legitimate need have access to your data.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Regular Audits: </b> Periodic reviews of our
                            security practices to identify and address
                            vulnerabilities.
                          </p>
                        </li>
                      </ul>
                      <br />
                      While we strive to protect your information, no system is
                      100% secure, and we cannot guarantee absolute protection
                      against unauthorized access.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Your Rights and Choices
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                Depending on your jurisdiction, you may have specific privacy
                rights under laws such as the General Data Protection Regulation
                (GDPR) or the California Consumer Privacy Act (CCPA). These may
                include:
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <p>
                      <ul>
                        <li>
                          <p>
                            <b>Right to Access: </b> Request a copy of the
                            personal information we hold about you.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Right to Correct: </b>Rectify inaccuracies in
                            your personal data.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Right to Delete: </b> Request the deletion of
                            your account or specific data, subject to legal
                            retention obligations.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Right to Data Portability: </b> Obtain a copy of
                            your data in a machine-readable format.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Right to Opt-Out: </b> Unsubscribe from marketing
                            emails or refuse data collection via cookies.
                          </p>
                        </li>
                      </ul>
                      <br />
                      To exercise your rights, email us at{" "}
                      <Link to="learning@rootpromptnext.com">
                        <b>learning@rootpromptnext.com</b>
                      </Link>
                      . Requests will be processed within 30 days unless
                      additional time is required.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Cookie Policy
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                Cookies are small text files stored on your device that help us
                enhance your experience on our platform. We use:
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <p>
                      <ul>
                        <li>
                          <p>
                            <b>Essential Cookies: </b> Necessary for website
                            functionality.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Analytical Cookies: </b>Help us understand user
                            behavior.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Preference Cookies: </b> Remember your settings
                            and preferences.
                          </p>
                        </li>
                      </ul>
                      <br />
                      <span>
                        You can manage cookie preferences in your browser
                        settings. Note that disabling cookies may limit some
                        functionalities of our website.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Third-Party Links
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                Our website may contain links to third-party websites or
                services. We are not responsible for the privacy practices or
                content of these external sites. We encourage you to read their
                privacy policies before providing any personal information.
              </p>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Changes to this Privacy Policy
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                This Privacy Policy may be updated periodically to reflect
                changes in our practices or legal obligations. All updates will
                be posted on this page, and we encourage you to review it
                regularly.
              </p>
              <br/>
              <span><b>Last Updated:</b> 20/11/2024</span>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
              Contact Us
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
              For questions, concerns, or requests regarding this Privacy Policy, please contact us:
              </p>
              <br/>
              <span><b>Email: </b> learning@rootpromptnext.com</span>
              <br/>
              <span><b>Address: </b> RootPromptNext (OPC) Pvt Ltd, C604, Gurukrupa Astter Mahadev Nagar, Pune, India 411 014</span>
              
            </div>
          </div>
        </div>
        <div className="td_height_50 td_height_lg_40"></div>
        
        <div className="text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s">
          <Link to="/" className="td_btn td_style_1 td_medium td_with_shadow_2">
            <span className="td_btn_in td_white_color td_accent_bg">
              <span>Back to Home</span>
            </span>             
          </Link>
        </div>
      </div>
      <div className="td_height_120 td_height_lg_80"></div>
    </section>
  );
};
