import React from 'react';
import { HomePage } from "./components/home/HomePage";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { About } from './components/about/About';
import { ContactUs } from './components/contactus/ContactUs';
import { CourseDetails } from './components/course/course-details/CourseDetails';
import { StartedCourseDetail } from './components/course/StartedCourseDetail';
import { CourseList } from './components/course/courseList/CourseList';
import { TermsAndConditions } from './components/constantcontents/terms_condtions/TermsAndConditions';
import { PrivacyAndPolicy } from './components/constantcontents/privacy_policy/PrivacyAndPolicy';
import { RefundPolicy } from './components/constantcontents/refund_policy/RefundPolicy';
import { Banner } from './components/dynamicbanner/Banner';
import { PurchesCourseForm } from './components/userform/PurchesCourseForm';
import { ErrorPage } from './components/constantcontents/ErrorPage';
import NotFound from './components/about/NotFound';

const startedCourseTimeingData  ={
  scheduleDate:"December 2nd, 2024",// dateFormat("2024-12-02T08:59:00.000Z", "mmmm dS, yyyy"),
  scheduleTime: "07.00 am - 09.00 am",
  durationPeriod: "2+ Months"
}
const courseListData  ={
  imageURL: "assets/img/others/coursesListImage.jpg",
  title:"Course List",
  parentTitle: "Home",
  childTitle: "Course List"
}
const aboutData  ={
  imageURL :"assets/img/others/Online_access.jpg",
  title:"About",
  parentTitle: "Home",
  childTitle: "About"
}
const contactData  ={
  imageURL :"assets/img/others/BannercontactUs.jpg",
  title:"Contact",
  parentTitle: "Home",
  childTitle: "Contact"
}
const viewCoursetData  ={
  imageURL :"assets/img/others/Online_access.jpg",
  title:"View Course",
  parentTitle: "Home",
  childTitle: "View Course"
}
const accessOnlineCourse  ={
  imageURL :"assets/img/others/Online_access.jpg",
  title:"Access Online Course",
  parentTitle: "Home",
  childTitle: "Access Online Course"
}

const route = createBrowserRouter([
    {
        path: '/',
        element: <HomePage/>,
       
    },
    {
      path: '/courselist',
      element: 
      <>
      <Banner {...courseListData}/>
      <CourseList/>
      </>
      ,
      
  },
    {
      path: '/courses',
      element: 
      <>
      <Banner {...viewCoursetData}/>
      <CourseDetails/>
      </>
      ,
      
  },
    {
      path: '/coursesdetails',
      element: 
      <>
      
      <StartedCourseDetail {...startedCourseTimeingData}/>
      </>
      
      ,
      
  },
    {
        path: '/about',
        element:
        <>
        <Banner {...aboutData}/>
        <About/>
        </>,
    },
    {
      path: '/contact',
      element:<>
      <Banner {...contactData}/>
      <ContactUs/>
      </>,
  },
  {
    path: '/termsandconditions',
    element: <TermsAndConditions/>
},
{
  path: '/privacyandpolicy',
  element: <PrivacyAndPolicy/>
},
{
  path: '/returnpolicy',
  element: <RefundPolicy/>
},
{
  path: '/purchescourseform',
  element: 
  <>
  <Banner {...accessOnlineCourse}/>
  <PurchesCourseForm/>
  </>
},
{
  path: '*',
  element: <NotFound/>,
},

]);

export const Router = () => {
  return (
    <>
     <RouterProvider router={route}/>
    
    </>
  )
}
