import { Link } from "react-router-dom";
import { PurchesCourseForm } from "../userform/PurchesCourseForm";
export const ContactUs = () => {
  return (
    <>
      <section>
        <div className="td_height_120 td_height_lg_75"></div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-10 offset-xxl-1">
              <div className="row align-items-center td_gap_y_40">
                <div className="col-lg-7">
                  <img
                    src="assets/img/others/contactus.jpg"
                    alt=""
                    className="w-100 shadow"
                  />
                </div>

                <div className="col-xl-4 offset-xl-1 col-lg-5">
                  <div className="td_contact_info">
                    <div className="td_section_heading td_style_2 td_mb_20">
                      <h2 className="td_contact_info_title td_fs_36 mb-0">
                        Our Office Address
                      </h2>
                    </div>
                    <div className="td_mb_40">
                      <h2 className="td_fs_24 td_semibold td_mb_20">
                        India Campus
                      </h2>

                      <ul className="td_footer_address_widget td_medium td_mp_0">
                        <li>
                          <i className="fa-solid fa-phone-volume"></i>
                          <Link to="cal:+91 842 121 8824">
                            +91 842 121 8824
                          </Link>
                        </li>
                        <li>
                          <i className="fa-solid fa-envelope"></i>
                          <Link to="learnwithprayag@gmail.com">
                            learnwithprayag@gmail.com
                          </Link>
                        </li>
                        <li>
                          <i className="fa-solid fa-location-dot"></i>C604,
                          Gurukrupa Astter, <br />
                          Kharadi, Pune 411 014. India
                        </li>
                      </ul>
                    </div>
                    <div
                      className=" td_section_heading  wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay="0.35s"
                    >
                      <p className=" td_fs_25 td_semibold td_spacing_1 td_mb_10 text-uppercase td_accent_color">
                        <i>
                          For any queries, contact us. We will be happy to
                          assist you at our earliest.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PurchesCourseForm />
          </div>
        </div>

        <div className="td_map">
          <div data-aos="fade-up">
            <iframe
              style={{ border: "0", width: "100%", height: "500px" }}
              src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=C604,%20Gurukrupa%20Astter,%20Mahadev%20Nagar,%20Vadgaonsheri,%20Pune+(LearnWithPrayag)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};
