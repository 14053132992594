
import React from "react";

const NotFound: React.FC = () => {
  return (
    <div>
        <div className="td_height_120 td_height_lg_80"></div>
        <div className="td_height_120 td_height_lg_80"></div>
      <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you're looking for does not exist.</p>
      <a href="/">Go back to Home</a>
    </div>
    <div className="td_height_120 td_height_lg_80"></div>
    </div>
  );
};

export default NotFound;
