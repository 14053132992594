import React from 'react';
import './App.css';
import { FooterPage } from './components/constantcontents/footer/FooterPage';
import { NavBarPage } from './components/constantcontents/NavBarPage';

import {Router} from './Router';

function App() {
  


  return (
    <>
    <NavBarPage/>
    <Router/>
    <FooterPage/>
    </>
  );
}

export default App;
