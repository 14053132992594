import React from "react";

export const Banner = (props: bannerDetaisProps) => {
  return (
    <section
      className="td_page_heading td_center td_bg_filed td_heading_bg text-center td_hobble"
      data-src={props.imageURL}
    >
      <div className="container">
        <div className="td_page_heading_in">
          <h1 className="td_white_color td_fs_48 td_mb_10">{props.title}</h1>
          <ol className="breadcrumb m-0 td_fs_20 td_opacity_8 td_semibold td_white_color">
            <li className="breadcrumb-item">
              <a href="/">{props.parentTitle}</a>
            </li>
            <li className="breadcrumb-item active">{props.childTitle}</li>
          </ol>
        </div>
      </div>
      <div className="td_page_heading_shape_1 position-absolute td_hover_layer_3"></div>
      <div className="td_page_heading_shape_2 position-absolute td_hover_layer_5"></div>
      <div className="td_page_heading_shape_3 position-absolute">
        <img src="assets/img/others/page_heading_shape_3.svg" alt="" />
      </div>
      <div className="td_page_heading_shape_4 position-absolute">
        <img src="assets/img/others/page_heading_shape_4.svg" alt="" />
      </div>
      <div className="td_page_heading_shape_5 position-absolute">
        <img src="assets/img/others/page_heading_shape_5.svg" alt="" />
      </div>
    </section>
  );
};

interface bannerDetaisProps {
  imageURL: string;
  title: string;
  parentTitle: string;
  childTitle: string;
}
