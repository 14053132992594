import React from "react";

export const FooterPage = () => {
  return (
    <footer className="td_footer td_style_1 td_color_1">
      <div className="container">
        <div className="td_footer_row">
          <div className="td_footer_col">
            <div className="td_footer_widget">
              <div className="td_footer_text_widget td_fs_18">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="170"
                  height="170"
                  viewBox="0 0 200 200"
                >
                  <circle cx="100" cy="100" r="90" fill="#0073e6" />
                  <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    fill="#ffffff"
                    fontSize="50"
                    fontFamily="Arial, sans-serif"
                    dy=".3em"
                  >
                    LWP
                  </text>
                </svg>
                <p>
                  At the DevOps Foundation Course, I take a hands-on,
                  instructor-led approach to learning.
                </p>
              </div>
              <ul className="td_footer_address_widget td_medium td_mp_0">
                <li>
                  <i className="fa-solid fa-phone-volume"></i>
                  <a href="cal:+91 842 121 8824">+91 842 121 8824</a>
                </li>
                <li>
                  <i className="fa-solid fa-location-dot"></i>C604, Gurukrupa
                  Astter, <br />
                  Kharadi, Pune 411 014. India
                </li>
              </ul>
            </div>
          </div>
          <div className="td_footer_col">
            <div className="td_footer_widget">
              <h2 className="td_footer_widget_title td_fs_32 td_white_color td_medium td_mb_30">
                Useful Links
              </h2>
              <ul className="td_footer_widget_menu">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/courselist">Courses</a>
                </li>
                <li>
                  <a href="/returnpolicy">Refund and Return policy</a>
                </li>
                <li>
                  <a href="/termsandconditions">Terms of service</a>
                </li>
                <li>
                  <a href="/privacyandpolicy">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="td_footer_col">
            <div className="td_footer_widget">
              <h2 className="td_footer_widget_title td_fs_32 td_white_color td_medium td_mb_30">
                Our Courses
              </h2>
              <ul className="td_footer_widget_menu">
                
                <li>
                  <a href="/courselist">AWS DevOps</a>
                </li>
                <li>
                  <a href="/courselist">DevSecOps</a>
                </li>
                
              </ul>
            </div>
          </div>
          <div className="td_footer_col">
            <div className="td_footer_widget">
              <h2 className="td_footer_widget_title td_fs_32 td_white_color td_medium td_mb_30">
                Subscribe Now
              </h2>
              <div className="td_newsletter td_style_1">
                <p className="td_mb_20 td_opacity_7">
                  Get an email, whenever I publish.
                </p>
                <form action="#" className="td_newsletter_form">
                  <input
                    type="email"
                    className="td_newsletter_input"
                    placeholder="Email address"
                  />
                  <button
                    type="submit"
                    className="td_btn td_style_1 td_radius_30 td_medium"
                  >
                    <span className="td_btn_in td_white_color td_accent_bg">
                      <span>Subscribe</span>
                    </span>
                  </button>
                </form>
              </div>
              <div className="td_footer_social_btns td_fs_20">
                <a href="#" className="td_center">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="#" className="td_center">
                  <i className="fa-brands fa-x-twitter"></i>
                </a>
                <a href="#" className="td_center">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="#" className="td_center">
                  <i className="fa-brands fa-pinterest-p"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="td_footer_bottom td_fs_18">
        <div className="container">
          <div className="td_footer_bottom_in">
            <p className="td_copyright mb-0">
              Copyright ©LearnWithPrayag {new Date().getFullYear().toString()} |
              A division of RootPromptNext Pvt Ltd. All Right Reserved
            </p>
            <ul className="td_footer_widget_menu">
              <li>
                <a href="/termsandconditions"> Terms & Conditions</a>
              </li>
              <li>
                <a href="/privacyandpolicy">Privacy & Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
