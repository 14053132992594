import React from "react";
import { Link } from "react-router-dom";

export const TermsAndConditions = () => {
  return (
    <section className="td_gray_bg_5">
      <div className="td_height_120 td_height_lg_80"></div>
      <div className="container">
        <div className="td_page_heading_in">
          <br />
          <h1 className="td_white_color td_fs_48 td_mb_10">
            Terms & Conditions
          </h1>
          <ol className="breadcrumb m-0 td_fs_20 td_opacity_8 td_semibold td_white_color">
            <li className="breadcrumb-item active">
              {" "}
              Effective Date: 20/11/2024
            </li>
          </ol>
        </div>
        <br />
      </div>
      <div className="container">
        <div
          className="td_section_heading td_style_1 text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        ></div>
        <div className="td_accordian_body td_fs_18">
          <p>
            Welcome to{" "}
            <Link to="www.learnwithprayag.com">
              <b>www.learnwithprayag.com</b>
            </Link>
            , a division of RootPromptNext (OPC) Pvt Ltd. These Terms and
            Conditions ("Terms") govern your use of our website, services, and
            the online courses we offer. By accessing or using this website and
            the services provided by
            <Link to="www.learnwithprayag.com">
              <b>www.learnwithprayag.com</b>
            </Link>{" "}
            ("we," "us," "our"), you agree to comply with these Terms. If you do
            not agree with any part of these Terms, you must refrain from using
            our platform.
          </p>
        </div>
        <div className="td_height_50 td_height_lg_50"></div>
        <div
          className="td_accordians td_style_1 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Acceptance of Terms
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                By accessing, browsing, or using our website and services, you
                agree to be bound by these Terms, our Privacy Policy, and any
                other terms and guidelines referenced herein. You acknowledge
                that you have read, understood, and accepted these terms.
              </p>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Eligibility
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                You must be at least <b>18 years old</b> or the legal age of
                majority in your jurisdiction to register, purchase, or access
                our services. If you are under the age of 18, you may use this
                website only under the supervision of a parent or guardian who
                agrees to these Terms.
              </p>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Account Registration
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                To access certain features, such as enrolling in courses or
                making purchases, you may need to create an account on
                www.learnwithprayag.com. You agree to:
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <p>
                      <ul>
                        <li>
                          <p>
                            Provide accurate, current, and complete information
                            during the registration process.
                          </p>
                        </li>
                        <li>
                          <p>
                            Maintain the confidentiality of your account
                            credentials and be responsible for all activities
                            under your account.
                          </p>
                        </li>
                        <li>
                          <p>
                            Notify us immediately of any unauthorized use of
                            your account.
                          </p>
                        </li>
                      </ul>
                      <br />
                      <span>
                        We reserve the right to suspend or terminate your
                        account if we suspect fraudulent activity, violations of
                        these Terms, or any illegal activity.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Course Enrollment and Purchases
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Course Access
                    </p>

                    <ul>
                      <br />
                      <li>
                        <p>
                          By enrolling in any course offered on
                          www.learnwithprayag.com, you gain access to the course
                          content subject to payment and other terms outlined in
                          the course description. Access to content may be
                          revoked if you violate any of these Terms.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Course Fees and Payments
                    </p>
                    <ul>
                      <br />
                      <li>
                        <p>
                          All course fees are displayed on the website and are
                          payable at the time of registration. We accept
                          payments through third-party gateways such as
                          credit/debit cards, UPI, and other approved methods.
                          You agree to pay all fees associated with your course
                          registration, including applicable taxes.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Changes to Course Content or Pricing
                    </p>

                    <ul>
                      <br />
                      <li>
                        <p>
                          We reserve the right to modify the course content,
                          pricing, or availability at any time. Any price
                          changes will be communicated prior to registration,
                          and you will have the option to cancel before
                          confirming your purchase.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Refund Policy
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                We do not provide refunds for any purchased course or
                subscription, as the first day of each course is a demo class.
                Once the course has been accessed, it is considered
                non-refundable.
              </p>
              <br />
              <p>
                If you encounter technical difficulties or issues with accessing
                your course, please contact our support team at
                learning@rootpromptnext.com. We will make reasonable efforts to
                resolve any issues promptly.
              </p>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Intellectual Property Rights
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Ownership of Content
                    </p>

                    <ul>
                      <br />
                      <li>
                        <p>
                          All course materials, including videos, text, images,
                          quizzes, and other educational content, are the
                          property of www.learnwithprayag.com and are protected
                          by intellectual property laws, including copyright,
                          trademark, and trade secret laws. You may use the
                          content solely for personal, non-commercial purposes.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      License to Use Content
                    </p>
                    <ul>
                      <br />
                      <li>
                        <p>
                          By purchasing or enrolling in a course, you are
                          granted a limited, non-transferable, non-exclusive
                          license to access and view the course content for
                          personal use only. You may not reproduce, distribute,
                          modify, or create derivative works from the course
                          materials without prior written consent from us.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                User Conduct
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>You agree not to engage in any of the following activities:</p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <p>
                      <ul>
                        <li>
                          <p>
                            <b>Violating laws:</b> Engaging in any activity that
                            violates applicable local, state, national, or
                            international laws.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Harassment:</b>Harassing, threatening, or
                            otherwise disturbing other users of the platform.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Impersonation:</b>Impersonating other individuals
                            or organizations.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Spamming:</b>Sending unsolicited advertisements,
                            promotional materials, or other forms of spam.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Unauthorized Use:</b>Attempting to bypass
                            security features or interfere with the functioning
                            of the website and courses.
                          </p>
                        </li>
                      </ul>
                      <br />
                      <span>
                        We reserve the right to suspend or terminate your
                        account if you engage in any prohibited conduct.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Third-Party Links and Services
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                Our website may contain links to third-party websites or
                services that are not operated by us. We have no control over
                and assume no responsibility for the content, privacy policies,
                or practices of third-party sites. You acknowledge and agree
                that we are not responsible for any harm or loss caused by your
                interaction with third-party sites or services.
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Limitation of Liability
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                To the fullest extent permitted by applicable law,
                www.learnwithprayag.com, its directors, employees, and agents
                shall not be liable for any indirect, incidental, special,
                consequential, or punitive damages, or for any loss of profit,
                data, or business interruption arising from your use or
                inability to use the website, services, or courses.
              </p>
              <br />
              <p>
                In any case, our total liability to you for any claim related to
                these Terms will not exceed the total fees paid by you for the
                course(s) giving rise to the claim, regardless of the legal
                theory under which the claim is based.
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Dispute Resolution
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                Any dispute arising out of or in connection with these Terms
                shall be resolved through arbitration in accordance with the
                Arbitration and Conciliation Act, 1996 of India. The place of
                arbitration shall be [insert city], India, and the proceedings
                shall be conducted in English.
              </p>
              <br />
              <p>
                If any legal action is required, you agree to submit to the
                jurisdiction of the courts located in [insert city], India.
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Privacy Policy
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                By using our website and services, you agree to our Privacy
                Policy, which outlines how we collect, use, and protect your
                personal information. Please read it carefully for a better
                understanding of your privacy rights and our practices.
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Modifications to Terms
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                We reserve the right to update or change these Terms at any time
                without prior notice. The updated Terms will be posted on this
                page with a revised Effective Date. It is your responsibility to
                review the Terms periodically for any changes. Your continued
                use of the website following the posting of any changes
                constitutes your acceptance of those changes.
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Termination
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                We may suspend or terminate your account and access to the
                platform at our discretion if you violate these Terms. Upon
                termination, all rights granted to you under these Terms will
                cease, and you must immediately stop using our services.
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Governing Law
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                These Terms shall be governed by and construed in accordance
                with the laws of India, without regard to its conflict of law
                principles.
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Contact Us
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                If you have any questions or concerns regarding these Terms and
                Conditions, please contact us at:
              </p>
              <br />
              <span>
                <b>Email: </b> learning@rootpromptnext.com
              </span>
              <br />
              <span>
                <b>Address: </b> RootPromptNext (OPC) Pvt Ltd, C604, Gurukrupa
                Astter Mahadev Nagar, Pune, India 411 014
              </span>
            </div>
          </div>
        </div>
        <div className="td_height_50 td_height_lg_40"></div>

        <div
          className="text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.35s"
        >
          <Link
            to="/privacyandpolicy"
            className="td_btn td_style_1 td_medium td_with_shadow_2"
          >
            <span className="td_btn_in td_white_color td_accent_bg">
              <span>Back to Privacyc & Policy</span>
            </span>
          </Link>
        </div>
      </div>
      <div className="td_height_120 td_height_lg_80"></div>
    </section>
  );
};
