import React from "react";
import { Link } from "react-router-dom";

export const RefundPolicy = () => {
  return (
    <section className="td_gray_bg_5">
      <div className="td_height_120 td_height_lg_80"></div>
      <div className="container">
        <div className="td_page_heading_in">
          <br />
          <h1 className="td_white_color td_fs_48 td_mb_10">Refund Policy</h1>
          <ol className="breadcrumb m-0 td_fs_20 td_opacity_8 td_semibold td_white_color">
            <li className="breadcrumb-item active">
              {" "}
              Effective Date: 20/11/2024
            </li>
          </ol>
        </div>
        <br />
      </div>
      <div className="container">
        <div
          className="td_section_heading td_style_1 text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        ></div>
        <div className="td_accordian_body td_fs_18">
          <p>
            At{" "}
            <Link to="www.learnwithprayag.com">
              <b>www.learnwithprayag.com</b>
            </Link>
            , a division of RootPromptNext (OPC) Pvt Ltd, we strive to ensure
            that our students receive the best learning experience. However, as
            we offer online courses with immediate access to materials, we have
            a strict no-refund policy. This Refund Policy outlines the
            circumstances under which refunds may be requested, as well as the
            terms under which they will be processed.
          </p>
          <br />
          <p>
            By purchasing or enrolling in any course offered on our platform,
            you agree to this Refund Policy. If you do not agree with these
            terms, you must refrain from making a purchase.
          </p>
        </div>
        <div className="td_height_50 td_height_lg_50"></div>
        <div
          className="td_accordians td_style_1 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                No Refund Policy
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                Due to the nature of online course delivery and the immediate
                access to course materials,{" "}
                <b>
                  we do not offer refunds under any circumstances once a course
                  has been purchased.
                </b>{" "}
                This applies to all courses, whether live or recorded.
              </p>
              <br />
              <p>
                However, we provide a <b>Demo Class</b> on the first day of each
                course to ensure that you can evaluate the quality of our
                training before committing to the full course. If you are not
                satisfied after attending the demo class, you may choose not to
                continue with the course, but no refund will be issued after
                accessing the course materials.
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Course Access and Evaluation
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <div className="td_mb_40 position-relative">
                <p>
                  All courses come with a demo class on the first day, which
                  provides an opportunity for you to assess the course content
                  and decide if it fits your expectations and learning needs.
                  Once you have accessed any part of the course content beyond
                  the demo class, you are bound by our no-refund policy.
                </p>
                <br />
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      Refund Eligibility Based on Demo Class
                    </p>

                    <ul>
                      <br />
                      <li>
                        <p>
                          If you attend the demo class and decide not to proceed
                          with the course, you are free to withdraw at that
                          time. No further course materials will be provided if
                          you decide not to continue. However,{" "}
                          <b>No Refunds </b>will be issued, even if you choose
                          to withdraw before the full course begins.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <svg
                      className="td_accent_color"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0166 23.0239C11.0131 23.0239 11.009 23.0239 11.0055 23.0239C10.8602 23.021 10.7231 22.9589 10.6246 22.8522L0.578144 11.928C0.405879 11.7405 0.390058 11.4581 0.540644 11.2524C0.69123 11.0473 0.965449 10.977 1.19631 11.0848L10.3914 15.3903C10.4688 15.4266 10.5602 15.4091 10.6194 15.3475L24.6455 0.687967C24.8354 0.489334 25.1459 0.467655 25.3615 0.638748C25.5772 0.809842 25.627 1.11687 25.4764 1.34715L11.524 22.721C11.5041 22.752 11.4807 22.7802 11.4549 22.8065L11.3934 22.868C11.2932 22.9677 11.1572 23.0239 11.0166 23.0239Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="td_list td_style_2 td_fs_24 td_medium td_heading_color td_mp_0">
                      No Refund After Course Materials Accessed
                    </p>
                    <ul>
                      <br />
                      <li>
                        <p>
                          Once the course materials (videos, assignments,
                          resources, etc.) are accessed by you, you are no
                          longer eligible for a refund, regardless of the time
                          elapsed.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Technical Issues or Accessibility Problems
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                In the event that you experience technical difficulties that
                prevent you from accessing course content, please contact our
                support team immediately at learning@rootpromptnext.com. We will
                make every reasonable effort to resolve technical issues
                promptly, such as providing alternative access or
                troubleshooting problems.
              </p>
              <br />
              <p>
                <b>
                  However, we will not issue refunds due to these technical
                  difficulties unless we are unable to resolve the issue and you
                  have not accessed any course materials.
                </b>
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Cancellations and Course Availability
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                If you encounter any issues with course availability, such as
                technical failures on our end or significant delays in accessing
                materials, we will work with you to resolve the problem. In case
                of any <b>course cancellation</b> initiated by{" "}
                <Link to="www.learnwithprayag.com">
                  <b>www.learnwithprayag.com</b>
                </Link>
                (e.g., due to unforeseen circumstances such as instructor
                unavailability), you will be offered one of the following
                options:
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <p>
                      <ul>
                        <li>
                          <p>
                            <b>A Full Refund:</b> If the course is canceled
                            before you have accessed any materials.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Course Rescheduling:</b>If the course can be
                            rescheduled, you may opt to attend the course at a
                            later time.
                          </p>
                        </li>
                      </ul>
                      <br />
                      <span>
                        Refunds will only be issued in the case of course
                        cancellations initiated by us and{" "}
                        <b>
                          will not be provided once you have accessed any course
                          materials.
                        </b>
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Refund Requests for Unused Course Materials
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                If you have not accessed the course materials after purchasing
                the course, you may submit a refund request within{" "}
                <b>7 days of purchase</b>. We will review your request and
                determine whether you are eligible for a refund based on the
                following criteria:
              </p>
              <br />
              <div className="td_mb_40 position-relative">
                <ul className="td_list td_style_2 td_mp_0">
                  <li>
                    <p>
                      <ul>
                        <li>
                          <p>
                            <b>No Access to Course Materials:</b> If you have
                            not accessed any course content, and the course has
                            been purchased within the past 7 days, a refund may
                            be considered at our discretion.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Technical Glitches:</b>If technical issues
                            prevent you from accessing the course, and we are
                            unable to resolve them, you may be eligible for a
                            refund.
                          </p>
                        </li>
                      </ul>
                      <br />
                      <span>
                        <b>Note:</b>
                        Refund requests submitted after the 7-day window will
                        not be considered.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Customer Support
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                If you encounter any issues, need assistance, or wish to inquire
                about a refund due to exceptional circumstances, please contact
                our support team.
              </p>
              <br />
              <p>
                <b>Email:</b>learning@rootpromptnext.com
              </p>
              <p>
                We will make reasonable efforts to resolve the issue in a timely
                and fair manner.
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Amendments to Refund Policy
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                We reserve the right to amend or modify this Refund Policy at
                any time. Any changes to this policy will be reflected on this
                page with an updated <b>Effective Date</b>. It is your
                responsibility to review this policy periodically for any
                updates. Your continued use of the website and courses after any
                changes to the Refund Policy constitutes your acceptance of
                those changes.
              </p>
            </div>
          </div>

          <div className="td_accordian ">
            <div className="td_accordian_head">
              <h2 className="td_accordian_title td_fs_36 td_normal">
                Governing Law
              </h2>
              <span className="td_accordian_toggle">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24811 1.49512C1.24811 1.49512 8.74803 7.01878 8.74805 8.99518C8.74807 10.9716 1.24805 16.4951 1.24805 16.4951"
                    stroke="#00001B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="td_accordian_body td_fs_18">
              <p>
                This Refund Policy shall be governed by and construed in
                accordance with the laws of India. Any disputes related to this
                Refund Policy will be subject to the jurisdiction of the courts
                located in Pune, India.
              </p>
            </div>
          </div>
        </div>
        <div className="td_height_50 td_height_lg_40"></div>
        <div className="text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s">
          <Link to="/contact" className="td_btn td_style_1 td_medium td_with_shadow_2">
            <span className="td_btn_in td_white_color td_accent_bg">
              <span>Back to Terms & Conditions</span>
            </span>             
          </Link>
        </div>
      </div>
      <div className="td_height_120 td_height_lg_80"></div>
    </section>
  );
};
